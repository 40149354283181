import type { UrlObject } from 'url';
import { ParsedUrlQuery } from 'node:querystring';
import { LinkProps } from 'next/link';
import { READY_ROUTES_FOR_PRODUCTION, ROUTES } from '@/constants/routes';
import { isDeepLink, startsWithHttp } from '@/utils/helpers';
import { mapQueryStringToPaymentData } from '@/views/Wallet/Finish.helpers';

export function buildURL(data: UrlObject) {
  const baseUrl = window.location.origin;
  const url = new URL(baseUrl + (data.pathname || ''));

  if (data.query) {
    for (const [key, value] of Object.entries(data.query)) {
      if (value != null) {
        url.searchParams.append(key, value.toString());
      }
    }
  }

  if (data.hash) {
    url.hash = data.hash;
  }

  return url.href;
}

type RouteKeys =
  | 'main'
  | 'category'
  | 'subcategory'
  | 'vip'
  | 'new'
  | 'adDetails'
  | 'userAds';

export function matchAdRoute(url: string, keysToCheck: RouteKeys[]) {
  const routes: Record<RouteKeys, RegExp> = {
    main: /^\/elanlar(\?.*)?$/,
    new: /^\/elanlar(?:\/[^/]+(?:\/[^/]+)?)?\/new$/,
    vip: /^\/elanlar(?:\/[^/]+(?:\/[^/]+)?)?\/vip$/,
    category: /^\/elanlar\/(?!new$|vip$|lightweight_form$)[^/]+$/,
    subcategory:
      /^\/elanlar\/(?!new$|vip$|lightweight_form$)[^/]+\/(?!new$|vip$|lightweight_form$)[^/]+$/,
    adDetails:
      /^\/elanlar\/(?!new$|vip$|lightweight_form$)[^/]+\/[^/]+\/\d+(?:[?#].*)?$/,
    userAds: /^\/elanlar\?user_id=\d+$/,
  };

  return Object.keys(routes).some(key => {
    const routeKey = key as RouteKeys;
    if (routes[routeKey].test(url)) {
      return keysToCheck.includes(routeKey);
    }
    return false;
  });
}

export function checkRoute(
  href: LinkProps['href'],
  query?: ParsedUrlQuery
): {
  isInternal?: boolean;
  hrefString: string;
} {
  const isHrefString = typeof href === 'string';
  const hrefString = isHrefString ? href : href.pathname || buildURL(href);

  const isInternal = !!READY_ROUTES_FOR_PRODUCTION.find(r => {
    if (r === ROUTES.ad && hrefString.includes(r)) {
      if (query?.cursor) return false; // for infinite scroll on the rails shop page

      return matchAdRoute(hrefString, [
        'main',
        'category',
        'subcategory',
        'adDetails',
        'userAds',
      ]);
    }

    if (r === ROUTES.walletFinish && hrefString.includes(r)) {
      return Boolean(query && mapQueryStringToPaymentData(query));
    }

    return r === hrefString;
  });

  if (
    !isInternal &&
    !startsWithHttp(hrefString) &&
    !isDeepLink(hrefString) &&
    typeof window !== 'undefined'
  ) {
    return { isInternal, hrefString: `${window.location.origin}${hrefString}` };
  }

  return { isInternal, hrefString };
}
