import React, { PropsWithChildren } from 'react';
import * as S from './Button.styles';
import { Spinner } from '@/components/Spinner/Spinner';
import { ButtonProps } from '@/components/UI/Button/Button.types';

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  shape,
  appearance,
  size = 'm',
  isFullWidth,
  isAutoWidth,
  hasPadding = true,
  isLoading,
  borderRadius,
  height,
  ...props
}) => {
  return (
    <S.ButtonBox
      {...props}
      type={props.type ?? 'button'}
      $shape={shape}
      $appearance={appearance}
      $size={size}
      $isFullWidth={isFullWidth}
      $isAutoWidth={isAutoWidth}
      $hasPadding={hasPadding}
      $isLoading={isLoading}
      $borderRadius={borderRadius}
      $height={height}
    >
      {isLoading ? <Spinner /> : children}
    </S.ButtonBox>
  );
};
