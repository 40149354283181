import { TFunction } from 'i18next';
import { AdKind } from '@/api/types/common.types';

export const promotions = (
  t: TFunction,
  minBumpedPrice: number,
  minVipPrice: number,
  minFeaturedPrice: number,
  hasBumped: boolean
) => [
  ...(hasBumped
    ? [
        {
          type: AdKind.Bumped,
          icon: 'upload-green.svg',
          title: t('pages.ad.up'),
          description: t('pages.ad.up_to_the_1_line'),
          minPrice: minBumpedPrice,
          boxLabel: null,
        },
      ]
    : []),
  {
    type: AdKind.Vipped,
    icon: 'vip.svg',
    title: t('pages.ad.vip'),
    description: t('pages.ad.up_and_show_in_special_section'),
    minPrice: minVipPrice,
    boxLabel: t('pages.ad.up_every_day'),
  },
  {
    type: AdKind.Premium,
    icon: 'premium.svg',
    title: t('pages.ad.premium'),
    description: t('pages.ad.up_and_show_on_home_page'),
    minPrice: minFeaturedPrice,
    boxLabel: `${t('pages.ad.up_every_day')} + ${t('pages.ad.vip')}`,
  },
];

export const BUMPS_PER_DAY = 3;
