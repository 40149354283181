import { NextPageContext } from 'next';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createApolloClient } from '@/config/apollo-client';
import i18n from '@/i18n';
import { getHostFromReq, isProduction } from '@/utils/helpers';
import { CONFIG } from '@/constants/config';
import { checkRoute } from '@/components/CustomLink/CustomLink.helpers';

function checkIsReadyRoutes(context: NextPageContext) {
  // There may be some POST requests coming from the Ruby application,
  // and React should respond with a 404 when the request URL matches the URL of an existing page.
  // (For example, submitting an advertisement)
  if (context.req?.method === 'POST') return false;

  const asPath = context.asPath ?? '';

  const route = asPath.split('?')[0];
  const { query } = context;
  const { isInternal } = checkRoute(route, query);
  return isInternal;
}

async function handleLanguage(host: string) {
  const currentLang = host.split('.')[0];
  const selectedLang = CONFIG.languages.includes(currentLang)
    ? currentLang
    : 'az';

  await i18n.changeLanguage(selectedLang);

  return selectedLang;
}

type InitialPropsData = Record<string, unknown>;

export function withPreRenderSettings(
  gip?: (
    context: NextPageContext,
    client: ApolloClient<NormalizedCacheObject>
  ) => Promise<InitialPropsData> | InitialPropsData,
  isErrorPage?: boolean
) {
  return async (context: NextPageContext) => {
    const host = context.req
      ? getHostFromReq(context.req)
      : window.location.host;
    const userAgent = context.req?.headers['user-agent'] || '';
    const isSSRWebView = userAgent.indexOf('miniApp') >= 0;

    if (!host) return { isSSRWebView };

    const isRouteReady = checkIsReadyRoutes(context);

    if (!isRouteReady && isProduction() && !isErrorPage && context.res) {
      context.res.statusCode = 404;
      context.res.end();
      return;
    }

    const currentLang = await handleLanguage(host);

    if (!gip) return { isSSRWebView };

    const apolloClient = createApolloClient(currentLang);

    const initialPropsData = await gip(context, apolloClient);

    const apolloState = apolloClient.extract();

    return { ...initialPropsData, isSSRWebView, apolloState };
  };
}
