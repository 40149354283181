export type MutationError = {
  message: string;
  code?: string;
  path?: string;
};

export type MutationResponse<Entity> = {
  entity: Entity;
  errors: MutationError[];
};

export enum AdKind {
  Bumped = 'BUMPED',
  Premium = 'PREMIUM',
  Vipped = 'VIPPED',
}

export type AssetUrl = {
  url: string;
};

export type Shop = {
  id: string;
  logo: string;
  name: string;
  uri: string;
  workingDays: string;
  workingHours: string;
  description: string;
};

export type Ad = {
  id: string;
  kinds: AdKind[];
  photo: AssetUrl;
  price: number | null;
  region: string;
  path: string;
  shop: Shop | null;
  title: string;
  updatedAt: string;
  legacyResourceId: number;
};

export type CreateCallResponse = {
  createCall: MutationResponse<string[]>;
};

export type CreateReportResponse = {
  createReport: MutationResponse<boolean>;
};

export enum PlatformSource {
  Mobile = 'MOBILE',
  App = 'APP',
  Desktop = 'DESKTOP',
}
