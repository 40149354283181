import { ParsedUrlQuery } from 'node:querystring';
import { TFunction } from 'i18next';
import { AdKind } from '@/api/types/common.types';
import { getBaseUrl, setSuffix } from '@/utils/helpers';
import i18n from '@/i18n';
import { PaymentMethod } from '@/api/types/payments.types';
import { QueryPayment } from '@/views/Wallet/Finish.types';
import { ROUTES } from '@/constants/routes';
import { BUMPS_PER_DAY } from '@/components/Ad/Promotions/Promotions.constants';

export const getPartialServiceLabel = (
  t: TFunction,
  value: string | number,
  objKey = 'n',
  tKey = 'common.n_days'
) => t(setSuffix(i18n, value.toString(), tKey), { [objKey]: value });

export function getServiceLabel(t: TFunction, payment: QueryPayment) {
  const { Bumped, Vipped, Premium } = AdKind;
  const { serviceType, period, categoryName, adsCount } = payment;

  if (serviceType === Bumped) {
    const label = getPartialServiceLabel(
      t,
      +period * BUMPS_PER_DAY,
      'n',
      'pages.ad.service_period_price'
    );
    return `${t('pages.ad.up_in_search')} / ${label}`;
  }

  if (serviceType && [Vipped, Premium].includes(serviceType)) {
    const service = serviceType === Vipped ? 'vip' : 'premium';
    return `${t(`pages.ad.${service}`)} ${getPartialServiceLabel(
      t,
      period,
      'days'
    )}`;
  }
  if (categoryName && adsCount) {
    return `${categoryName} - ${getPartialServiceLabel(
      t,
      adsCount,
      'n',
      'common.n_ads'
    )} / ${payment.price} AZN`;
  }

  if (payment.privateAccountId) return t('common.add_balance');

  return '';
}

export function mapQueryStringToPaymentData(
  query: ParsedUrlQuery
): QueryPayment | null {
  const {
    price,
    date,
    id,
    adId,
    serviceType,
    period,
    paymentMethod,
    categoryName,
    adsCount,
    privateAccountId,
  } = query;

  if (!price || !date || !id || !paymentMethod) {
    return null;
  }

  const baseFields = {
    price: String(price),
    date: String(date).replace(' ', '+'),
    id: String(id),
    paymentMethod: String(paymentMethod) as PaymentMethod,
  };

  if (serviceType && period) {
    return {
      ...baseFields,
      adId: String(adId),
      serviceType: String(serviceType) as AdKind,
      period: String(period),
    };
  }

  if (categoryName && adsCount !== undefined) {
    return {
      ...baseFields,
      adId: String(adId),
      categoryName: String(categoryName),
      adsCount: String(adsCount),
    };
  }

  if (privateAccountId) {
    return {
      ...baseFields,
      privateAccountId: String(privateAccountId),
    };
  }

  return null;
}

export function mapPaymentDataToUrl(paymentData: QueryPayment) {
  const { id, price, date, paymentMethod } = paymentData;
  const baseUrl = getBaseUrl(window.location.href);
  const urlParams = new URLSearchParams({
    id,
    price,
    date,
    paymentMethod,
  });

  if (
    'serviceType' in paymentData &&
    'period' in paymentData &&
    'adId' in paymentData &&
    paymentData.serviceType &&
    paymentData.period &&
    paymentData.adId
  ) {
    urlParams.append('serviceType', paymentData.serviceType);
    urlParams.append('period', paymentData.period);
    urlParams.append('adId', paymentData.adId);
  } else if (
    'categoryName' in paymentData &&
    'adsCount' in paymentData &&
    'adId' in paymentData &&
    paymentData.categoryName &&
    paymentData.adsCount &&
    paymentData.adId
  ) {
    urlParams.append('categoryName', paymentData.categoryName);
    urlParams.append('adsCount', paymentData.adsCount.toString());
    urlParams.append('adId', paymentData.adId);
  } else if (
    'privateAccountId' in paymentData &&
    paymentData.privateAccountId
  ) {
    urlParams.append('privateAccountId', paymentData.privateAccountId);
  }

  return `${baseUrl}${ROUTES.walletFinish}?${urlParams.toString()}`;
}
